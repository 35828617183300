import React, { FC } from "react";
import Head from "next/head";

import { ArticleData_articleCollection_items } from "../../../__generated__/ArticleData";
import { speciesEnum } from "../../constants";
import { getSiteUrl } from "../../utils/site";

interface IArticleHeadProps {
  article: ArticleData_articleCollection_items | null;
}

const ArticleHead: FC<IArticleHeadProps> = ({ article }) => (
  <Head>
    <meta
      name="apple-itunes-app"
      content={`app-id=1533808157, app-argument=lassieapp://articles/${article?.sys.id}`}
    />
    <meta
      name="keywords"
      content={[
        speciesEnum[article?.species?.code ?? ""] ?? "",
        article?.topicsCollection?.items.map((item) => item?.name).join(", "),
      ].join(", ")}
    />
    <meta name="author" content="Lassie" />
    <meta
      property="og:title"
      content={`${article?.metadataTitle ?? article?.title}`}
    />
    <meta property="og:description" content={`${article?.shortText}`} />
    <meta property="og:url" content={`${getSiteUrl(article?.uri)}`} />
    <meta property="og:image" content={`${article?.mainImage?.url}`} />
    <meta property="og:type" content="website" />
    <meta property="fb:app_id" content="723156671567662" />
  </Head>
);

export default ArticleHead;
