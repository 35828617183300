export const speciesEnum: Record<string, string> = {
  dog: "hund",
  cat: "katt",
};

export const speciesReverseEnum: Record<string, string> = {
  hund: "dog",
  katt: "cat",
};

export const faqTagEnum: Record<string, string> = {
  insurance: "faqFrskringFrFleraDjur",
  aboutUs: "faqOmOss",
  referral: "faqKompisRabatt",
  inCaseOfInjury: "faqVidSkada",
  inLassieApp: "faqInLassieApp",
  atInjury: "faqSkador",
  terms: "faqTerms",
  catExternal: "faqCatExternalAds",
  dogExternal: "faqDogExternalAds",
  breeders: "faqBreeders",
  breedersAddOn: "faqBreedersAddOn",
  puppyPackages: "faqPuppyPackages",
  germanyLandingPage: "faqGermanyLandingPage",
};
