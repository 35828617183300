import React, { useEffect, useState } from "react";

interface ArticleStructuredDataProps {
  headline: string;
  description?: string;
  image: string | null;
  datePublished?: string;
  author?: string | null;
}
const ArticleStructuredData = ({
  headline,
  description,
  image,
  datePublished,
  author,
}: ArticleStructuredDataProps) => {
  const [data, setData] = useState("");
  useEffect(() => {
    setData(
      JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Article",
        headline,
        description,
        image,
        datePublished,
        author: {
          "@type": "Person",
          name: author,
        },
      })
    );
  }, []);
  return <script type="application/ld+json">{data}</script>;
};
ArticleStructuredData.defaultProps = {
  datePublished: undefined,
  description: undefined,
  author: undefined,
};

export default ArticleStructuredData;
