import React from "react";
import Image from "next/image";
import Link from "next/link";
import classNames from "classnames";
import { logEvent } from "@amplitude/analytics-browser";
import { Typography } from "ui-kit/Typography";
import { Button } from "ui-kit/Button";
import { FlexRow } from "ui-kit/Layout/Flex";
import AppImage from "assets/images/AppIcon.png";
import { useWebTranslation } from "translations";
import { useStickyNavigation } from "components/Header/useStickyNavigation";
import styles from "./SmartAppBanner.module.css";

const SmartAppBanner = () => {
  const { t } = useWebTranslation();
  const { hide } = useStickyNavigation();

  return (
    <FlexRow
      justifyContent="space-between"
      alignItems="center"
      className={classNames(styles.container, hide && styles.headerHidden)}
    >
      <FlexRow alignItems="center">
        <FlexRow alignItems="center" style={{ marginLeft: 10 }}>
          <Image
            src={AppImage}
            alt="Lassie App"
            width={55}
            height={55}
            style={{ borderRadius: 10 }}
          />
          <div style={{ marginLeft: 15 }}>
            <Typography
              variant="body3"
              style={{ display: "block", fontWeight: "bold" }}
            >
              {t("articles.smart_banner.title")}
            </Typography>
            <Typography variant="body3" style={{ display: "block" }}>
              {t("articles.smart_banner.text")}
            </Typography>
          </div>
        </FlexRow>
      </FlexRow>
      <Link href="/app">
        <Button
          variant="custom"
          small
          color="blue300"
          textColor="white"
          title={t("articles.smart_banner.btn")}
          onPress={() => logEvent("GO_TO_STORE_PRESSED")}
        />
      </Link>
    </FlexRow>
  );
};

export default SmartAppBanner;
